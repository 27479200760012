.logo {
  position: absolute;
  font-family: 'IBM Plex Mono', monospace;
  color: #222;
  font-size: 40px;
  top: 20px;
  left: 20px;

  .subtitle {
    color: #999;
    margin-left: 3px;
    margin-top: 15px;
    font-size: 20px;
  }
}

.visualization {
  float: left;
  width: 60%;
  height: 100vh;
  position: relative;

  .time-control {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    z-index: 950;
    max-width: 14rem;

    .time-control-statistic {
      opacity: 0.8;
    }
  }

  .realtime-statistics {
    max-width: 20rem;
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1000;
  }

  .charge-station {
    width: 30%;
    position: absolute;
    z-index: 200;
    left: 40%;
    bottom: 10%;
  }

  @keyframes pulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  .charge-station-status {
    width: 30%;
    position: absolute;
    z-index: 200;
    left: 40%;
    bottom: 10%;
    img {
      opacity: 1;
    }
  }

  .charge-station-status.charging {
    img {
      animation: pulse 2s infinite;
    }
  }

  @keyframes parkCar1 {
    0% {
      left: -40%;
      bottom: -4%;
    }
    100% {
      left: -1%;
      bottom: 5%;
    }
  }

  @keyframes parkCar1Reverse {
    0% {
      left: -1%;
      bottom: 5%;
    }
    100% {
      left: -90%;
      bottom: -4%;
    }
  }

  @keyframes parkCar2 {
    0% {
      left: -120%;
      bottom: -4%;
    }
    100% {
      left: -50%;
      bottom: 20%;
    }
  }

  @keyframes parkCar2Reverse {
    0% {
      left: -50%;
      bottom: 20%;
    }
    100% {
      left: -120%;
      bottom: -4%;
    }
  }

  .car-1 {
    animation: parkCar1Reverse 3s 1 normal ease-out;
    position: absolute;
    z-index: 900;
    width: 100%;
    height: 30%;
    left: -90%;
    bottom: -4%;
  }

  .car-1.initial {
    display: none;
  }

  .car-1.animated {
    animation: parkCar1 3s 1 normal ease-out;
    left: -1%;
    bottom: 5%;
  }

  .car-2 {
    animation: parkCar2Reverse 3s 1 normal ease-out;
    position: absolute;
    z-index: 100;
    width: 90%;
    height: 30%;
    left: -120%;
    bottom: -4%;
  }

  .car-2.initial {
    display: none;
  }

  .car-2.animated {
    animation: parkCar2 3s 1 normal ease-out;
    left: -50%;
    bottom: 20%;
  }
}

.terminal {
  float: left;
  background: #222;
  width: 40%;
  height: 100vh;
  padding: 1em 0;
  overflow-y: scroll;

  .actions {
    margin-bottom: 1em;
    padding: 0 1em;

    .right-actions {
      float: right;
    }
  }

  .sub-actions {
    margin-bottom: 2em;
    padding: 0 1em;

    .right-actions {
      float: right;
    }
  }

  .console {
    color: #ccc;
    font-family: 'IBM Plex Mono', monospace;

    .log-entry {
      margin-bottom: 8px;
      padding: 0 1em;

      .params-summary {
        color: #777;
      }

      .params-summary .value {
        color: #fff;
      }
      .params-summary .key:not(:first-child) {
        margin-left: 10px;
      }

      .date-time {
        float: right;
        color: #777;
        font-size: 90%;
      }
    }

    .log-entry.command:hover {
      cursor: pointer;
      background-color: #303030;
    }

    .response,
    .message-response {
      color: #999;
    }

    .error {
      color: #ffaaaa;
    }
  }

  .tick {
    display: none;
  }
}

.road-logo {
  position: absolute;
  bottom: 1em;
  right: 1em;

  img {
    height: 20px;
  }
}

body .dashboard {
  overflow-y: hidden;
}
